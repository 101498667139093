import {Controller, useForm} from "react-hook-form";
import {Configuracoes} from "types/configuracoes";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {ConfiguracoesService} from "services/ConfiguracoesService";
import Format from "utils/formatadores";
import {toastError} from "utils/toastError";
import {Box, Button, Divider, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {AUTH_TYPES} from "utils/constants";
import SaveIcon from "@mui/icons-material/Save";
import {useAppDispatch, useAppSelector} from "store";
import {hasAnyRoles, useAuthState} from "store/auth";
import {configActions, useConfigState} from "store/config";

type Props = {
    configuracao?: Configuracoes;
    update: () => void;
}

const agendamentoParcial = [{value: "0", label: 'Não'}, {value: "1", label: 'Sim'}];


const ConfiguracaoGrupo = ({configuracao, update}: Props) => {
    const { handleSubmit, formState: {errors}, setValue, register, control } = useForm<Configuracoes>({mode: 'all', reValidateMode: 'onSubmit'});
    const config = useAppSelector(useConfigState);
    const dispatch = useAppDispatch();
    const appSelector = useAppSelector(useAuthState);


    const hasModuloPermission = (config: any, modulo: string) => {
        const moduloPermissao = config?.parametros?.permissoes?.find((permissao: string) => permissao === modulo);
        return !!moduloPermissao;
    }


    useEffect(() => {
        if (configuracao) {
            setValue('url', configuracao.url);
            setValue('authType', configuracao.authType ? configuracao.authType : 'NONE');
            setValue('usuario', configuracao.usuario);
            if (configuracao.senha) {
                setValue('senha', configuracao.senha);
            }
            setValue('logo', configuracao.logo);
            setValue('dominio', configuracao.dominio);
            setValue('usuario', configuracao.usuario);
            setValue('senha', configuracao.senha);
            setValue('mfa', configuracao.mfa);

            setValue('email.host', configuracao.email.host);
            setValue('email.port', configuracao.email.port);
            setValue('email.auth', configuracao.email.auth);
            setValue('email.username', configuracao.email.username);
            setValue('email.protocol', configuracao.email.protocol);
            setValue('email.starttls', configuracao.email.starttls);

            setValue('agendamentoEntrega.horarioMin', configuracao.agendamentoEntrega.horarioMin);
            setValue('agendamentoEntrega.horarioMax', configuracao.agendamentoEntrega.horarioMax);
            setValue('agendamentoEntrega.intervaloHora', configuracao.agendamentoEntrega.intervaloHora);
            setValue('agendamentoEntrega.diasMin', configuracao.agendamentoEntrega.diasMin);
            setValue('agendamentoEntrega.diasMax', configuracao.agendamentoEntrega.diasMax);
            setValue('agendamentoEntrega.diasToCancel', configuracao.agendamentoEntrega.diasToCancel);
            setValue('agendamentoEntrega.agendamentoParcial', configuracao.agendamentoEntrega.agendamentoParcial);
            setValue('validade', configuracao.validade ? Format.dataZ(configuracao.validade) : '');
        }
    }, [configuracao, setValue]);

    const onSubmit = (data: any) => {
        if (!config.parametros.codigoUnico) {
            toast.error("Grupo não selecionado")
            return;
        };
        if(data.authType !== 'NONE' && !data.usuario && !data.senha){
            toast.error("Usuário e senha são obrigatórios para autenticação")
            return;
        }
        data.diasMax = parseInt(data.diasMax);
        data.diasMin = parseInt(data.diasMin);
        data.diasToCancel = parseInt(data.diasToCancel);
        data.validade = data.validade ? Format.dataUTC(data.validade, "20:59:00") : null;
        data.agendamentoEntrega.agendamentoParcial = data.agendamentoEntrega.agendamentoParcial === "1";
        data.email.password = data.email.password ? data.email.password : '';
        dispatch(configActions.setLoading({
            loading: true,
        }));
        ConfiguracoesService.update(config.parametros.codigoUnico, data)
            .then(() => {
                update();
                toast.success("Configurações atualizadas com sucesso");
            })
            .catch((err) => {
                toastError(err, "Erro ao atualizar configurações!")
            })
            .finally(() => dispatch(configActions.setLoading({
                loading: false,
            })));
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                <Box sx={{mb: 3}}>
                    <Typography>Configurações do sistema</Typography>
                    <Divider/>
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            {...register('url')}
                            required
                            error={!!errors.url}
                            type={"url"}
                            label="Url Conexão"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.url?.message}
                            InputLabelProps={{shrink: true}}
                            InputProps={{readOnly: !hasAnyRoles(appSelector, ["ROLE_ADMIN"])}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            {...register('authType')}
                            error={!!errors.authType}
                            required
                            select
                            label="Tipo de Autenticação"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.authType?.message}
                            InputLabelProps={{shrink: true}}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {AUTH_TYPES.map((type) => (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            {...register('usuario', {
                                // required: {value: getValues('authType') === 'NONE' ? false: true, message: "Campo obrigatório"},
                                // validate: (value) => {
                                //     if (getValues('authType') !== 'NONE' && value.trim().length === 0) return "Campo obrigatório";
                                // }
                            })}
                            error={!!errors.usuario}
                            type={"text"}
                            label="Usuário de Conexão"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.usuario?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            {...register('senha', {
                                // required: {value: getValues('authType') === 'NONE' ? false: true, message: "Campo obrigatório"},
                                // validate: (value) => {
                                //     if (getValues('authType') !== 'NONE' && value.trim().length === 0) return "Campo obrigatório";
                                // }
                            })}
                            error={!!errors.senha}
                            type={"password"}
                            label="Senha de Conexão"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.senha?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            {...register('logo')}
                            error={!!errors.logo}
                            type={'url'}
                            label="Url da logo"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.logo?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            {...register('dominio')}
                            error={!!errors.dominio}
                            type={'text'}
                            label="Dominio"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.dominio?.message}
                            InputLabelProps={{shrink: true}}
                            InputProps={{readOnly: !hasAnyRoles(appSelector,["ROLE_ADMIN"])}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            {...register('validade')}
                            error={!!errors.validade}
                            type={'date'}
                            label="Validade"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.validade?.message}
                            InputLabelProps={{shrink: true}}
                            InputProps={{readOnly: !hasAnyRoles(appSelector,["ROLE_ADMIN"])}}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Controller
                            name="mfa"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    error={!!errors.mfa}
                                    select
                                    label="MFA"
                                    value={field.value ? field.value : "false"}
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.mfa?.message}
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{readOnly: !hasAnyRoles(appSelector,["ROLE_GRUPO_EMPRESA", "ROLE_ADMIN"])}}
                                >
                                    <MenuItem value="true">Sim</MenuItem>
                                    <MenuItem value="false">Não</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                </Grid>

                <Box sx={{my: 3}}>
                    <Typography>Configurações serviço de email</Typography>
                    <Divider/>
                </Box>
                <Grid container spacing={2}>
                <Grid item xs={6} >
                        <TextField
                            {...register('email.host')}
                            error={!!errors.email?.host}
                            type={'text'}
                            label="Host"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.email?.host?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item xs={3} >
                        <TextField
                            {...register('email.port')}
                            error={!!errors.email?.port}
                            type={'number'}
                            label="Porta"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.email?.port?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item xs={3} >
                        <Controller
                            name="email.auth"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    error={!!errors.email?.auth}
                                    select
                                    label="Autenticação"
                                    value={field.value ? field.value : "false"}
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.email?.auth?.message}
                                    InputLabelProps={{shrink: true}}
                                >
                                    <MenuItem value="true">Sim</MenuItem>
                                    <MenuItem value="false">Não</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                   
                    <Grid item xs={6} >
                        <TextField
                            {...register('email.username')}
                            error={!!errors.email?.username}
                            type={'text'}
                            label="Usuário"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.email?.username?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <TextField
                            {...register('email.password')}
                            error={!!errors.email?.password}
                            type={'password'}
                            label="Senha"
                            variant="outlined"
                            sx={{width: '100%'}}
                            helperText={errors.email?.password?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="email.protocol"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    error={!!errors.email?.protocol}
                                    select
                                    label="Protocolo"
                                    value={field.value ? field.value : "smtp"}
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.email?.protocol?.message}
                                    InputLabelProps={{shrink: true}}
                                >
                                    <MenuItem value="smtp">SMTP</MenuItem>
                                    <MenuItem value="pop3">POP3</MenuItem>
                                    <MenuItem value="imap">IMAP</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="email.starttls"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    error={!!errors.email?.starttls}
                                    select
                                    label="STARTTSL"
                                    value={field.value ? field.value : "false"}
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.email?.starttls?.message}
                                    InputLabelProps={{shrink: true}}
                                >
                                    <MenuItem value="true">Sim</MenuItem>
                                    <MenuItem value="false">Não</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                </Grid>            

                {hasModuloPermission(config, "AGENDAMENTO_ENTREGA") && (
                    <>
                        <Box sx={{my: 3}}>
                            <Typography>Configurações de agendamento de carga</Typography>
                            <Divider/>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Controller
                                    name={"agendamentoEntrega.agendamentoParcial"}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            error={!!errors.agendamentoEntrega?.agendamentoParcial}
                                            required
                                            select
                                            label="Agendamento Parcial"
                                            variant="outlined"
                                            value={field.value ? "1" : "0"}
                                            fullWidth
                                            helperText={errors.agendamentoEntrega?.agendamentoParcial?.message.toString()}
                                        >
                                            {agendamentoParcial.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>))}
                                        </TextField>
                                    )}
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    {...register('agendamentoEntrega.horarioMin')}
                                    required
                                    error={!!errors.agendamentoEntrega?.horarioMin}
                                    type={'time'}
                                    label="Horario min."
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.agendamentoEntrega?.horarioMin?.message}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    {...register('agendamentoEntrega.horarioMax')}
                                    required
                                    error={!!errors.agendamentoEntrega?.horarioMax}
                                    type={'time'}
                                    label="Horario max."
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.agendamentoEntrega?.horarioMax?.message}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    {...register('agendamentoEntrega.intervaloHora')}
                                    required
                                    error={!!errors.agendamentoEntrega?.intervaloHora}
                                    type={'time'}
                                    label="Intervalo de Horas"
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.agendamentoEntrega?.intervaloHora?.message}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    {...register('agendamentoEntrega.diasMin')}
                                    required
                                    error={!!errors.agendamentoEntrega?.diasMin}
                                    type={'number'}
                                    label="Dias min."
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.agendamentoEntrega?.diasMin?.message}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>

                                <TextField
                                    {...register('agendamentoEntrega.diasMax')}
                                    required
                                    error={!!errors.agendamentoEntrega?.diasMax}
                                    type={'number'}
                                    label="Dias max."
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.agendamentoEntrega?.diasMax?.message}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    {...register('agendamentoEntrega.diasToCancel')}
                                    required
                                    error={!!errors.agendamentoEntrega?.diasToCancel}
                                    type={'number'}
                                    label="Dias para cancelamento"
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.agendamentoEntrega?.diasToCancel?.message}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                
                                                

                <Box sx={{mt: 3, textAlign: 'right'}}>
                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                        <SaveIcon/> Salvar
                    </Button>
                </Box>
            </Box>
        </>
    );
}


export default ConfiguracaoGrupo;