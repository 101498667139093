import { Avatar, Box, Grid, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAuthContext } from "contexts/AuthContext";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store";
import { configActions, useConfigState } from "store/config";
import Copyright from "components/copyright";

type MfaProps = {
    handleClose: (value: boolean) => void;
}

const Mfa = ({ handleClose }: MfaProps) => {
    const navigate = useNavigate();
    const { loginMfa } = useAuthContext();
    const { loading } = useAppSelector(useConfigState);
    const { handleSubmit, setValue, formState: { errors } } = useForm();
    const appDispatch = useAppDispatch();
    const [digits, setDigits] = useState<string[]>(Array(6).fill(""));
    const [attempts, setAttempts] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (attempts >= 3) {
            const timer = setTimeout(() => {
                handleClose(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [attempts, handleClose]);

    const handleDigitChange = (index: number, value: string) => {
        const newDigits = [...digits];
        newDigits[index] = value.replace(/\D/g, "");
        setDigits(newDigits);
        setErrorMessage("");
        
        if (value && index < 5) {
            document.getElementById(`digit-${index + 1}`)?.focus();
        }

        setValue("code", newDigits.join(""));

        if (value && index === 5) {
            handleSubmit(onSubmit)();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === 'Backspace') {
            if (!digits[index] && index > 0) {
                const newDigits = [...digits];
                newDigits[index - 1] = '';
                setDigits(newDigits);
                document.getElementById(`digit-${index - 1}`)?.focus();
            }
            else if (digits[index]) {
                const newDigits = [...digits];
                newDigits[index] = '';
                setDigits(newDigits);
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text/plain").replace(/\D/g, "");
        const pastedCode = pastedData.substring(0, 6);
        const newDigits = [...pastedCode.split(""), ...Array(6).fill("")].slice(0, 6);
        
        setDigits(newDigits);
        setValue("code", newDigits.join(""));
        
        if (pastedCode.length === 6) {
            document.getElementById(`digit-5`)?.focus();
            handleSubmit(onSubmit)();
        }
    };

    const onSubmit = (data: { code: string }) => {
        appDispatch(configActions.setLoading({ loading: true }));
        setErrorMessage("");
    
        loginMfa(data.code)
            .then(() => {
                navigate("/painel", { replace: true });
                setAttempts(0);
            })
            .catch(() => {
                const newAttempts = attempts + 1;
                setAttempts(newAttempts);
                setDigits(Array(6).fill(""));
                setValue("code", "");
                
                if (newAttempts >= 3) {
                    setErrorMessage("Número máximo de tentativas excedido. Voltando para login...");
                } else {
                    setErrorMessage(`Código inválido. Tentativas restantes: ${3 - newAttempts}`);
                }
            })
            .finally(() => appDispatch(configActions.setLoading({ loading: false })));
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" mb={2}>
                Autenticação de Dois Fatores
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                <Grid container spacing={2} justifyContent="center" onPaste={handlePaste}>
                    {digits.map((digit, index) => (
                        <Grid item key={index}>
                            <TextField
                                id={`digit-${index}`}
                                value={digit}
                                onChange={(e) => handleDigitChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputProps={{
                                    maxLength: 1,
                                    style: { textAlign: "center" },
                                    autoComplete: "off",
                                    inputMode: "numeric",
                                }}
                                autoComplete="off"
                                autoCorrect="off"
                                sx={{ width: "50px" }}
                                error={!!errors.code || attempts > 0}
                            />
                        </Grid>
                    ))}
                </Grid>

                {errorMessage && (
                    <Typography 
                        color="error" 
                        variant="body2" 
                        sx={{ 
                            mt: 2, 
                            textAlign: 'center', 
                            fontWeight: 'bold',
                            minHeight: '24px'
                        }}
                    >
                        {errorMessage}
                    </Typography>
                )}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading || attempts >= 3}
                >
                    {loading ? "Verificando..." : "Verificar Código"}
                </Button>
                
                <Grid container justifyContent="left">
                    <Grid item>
                        <Button 
                            onClick={() => handleClose(false)} 
                            variant="text" 
                            sx={{ color: "primary.main" }}
                            disabled={attempts >= 3}
                        >
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
                
                <Grid container>
                    <Grid item xs>
                        <Copyright sx={{ p: 5 }} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Mfa;