import LoginLogo from 'assets/imgs/logo-login.png';
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/index";
import {Acesso} from "types/auth";
import {ConfigResposta, ConfigWhite} from "types/configuracoes";


export type ConfigState = {
    parametros: ConfigResposta | null;
    empresa: Acesso | null;
    modalConfig: boolean;
    modalEmpresa: boolean;
    openMenu: boolean;
    loading: boolean;
    configWhite: ConfigWhite;
}


const initialState: ConfigState = {
    parametros: null,
    empresa: null,
    modalConfig: false,
    modalEmpresa: false,
    openMenu: false,
    loading: false,
    configWhite: {
        dominio: null,
        logo: LoginLogo,
        codigo: '',
        nome: "FrontFlow"
    }
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        signout: (state) => {
            state.parametros = null;
            state.empresa = null;
            state.modalConfig = false;
            state.modalEmpresa = false;
            state.openMenu = true;
            state.loading = false;
        },
        setParametros: (state, action: PayloadAction<{parametros: ConfigResposta|null }>) => {
            state.parametros = action.payload.parametros;
            state.empresa = null;
        },
        setEmpresa: (state, action: PayloadAction<{empresa: Acesso | null}>) => {
            state.empresa = action.payload.empresa;
        },
        setConfigWhite: (state, action: PayloadAction<{ configWhite: ConfigWhite }>) => {
            state.configWhite = action.payload.configWhite;
        },
        setModalConfig: (state, action: PayloadAction<{modalConfig: boolean }>) => {
            state.modalConfig = action.payload.modalConfig;
        },
        setModalEmpresa: (state, action: PayloadAction<{modalEmpresa: boolean }>) => {
            state.modalEmpresa = action.payload.modalEmpresa;
        },
        setOpenMenu: (state, action: PayloadAction<{ openMenu: boolean}>) => {
            state.openMenu = action.payload.openMenu;
        },
        setLoading: (state, action: PayloadAction<{ loading: boolean }>) => {
            state.loading = action.payload.loading;
        }
     }
})

export default configSlice.reducer;

export const configActions = configSlice.actions;


export const useConfigState = (state: RootState) => state.config;
