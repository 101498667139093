import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogContent, DialogTitle, MenuItem, Select, Slide} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {TransitionProps} from "@mui/material/transitions";
import {AxiosRequestConfig} from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {ConfiguracoesService} from "services/ConfiguracoesService";
import {ConfigResposta, Configuracoes} from "types/configuracoes";
import {SpringPage} from "types/springpage";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import { useAuthState } from "store/auth";

type Props = {
    title: string;
};

type FormConfig = {
    config: Configuracoes;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const MyModalChangeGrupo = ({title}: Props) => {
    const [page, setPage] = useState<SpringPage<ConfigResposta>>();
    const {handleSubmit, register} = useForm<FormConfig>({});
    const appDispatch = useAppDispatch();
    const {modalConfig} = useAppSelector(useConfigState);
    const selector = useAppSelector(useAuthState);

    const onClose = () => {
        appDispatch(configActions.setModalConfig({modalConfig: false}));
    }

    const onSubmit = async (data: any) => {
        if (data.config !== "NONE") {
            let config = page?.content.find((c) => c.codigoUnico === data.config);
            if (config) {
                appDispatch(configActions.setParametros({parametros: config}));
                onClose();
                appDispatch(configActions.setModalEmpresa({modalEmpresa: true}));
            }
        }
    };

    const getConfiguracoes = useCallback(() => {
        if (!selector) return;
        const params: AxiosRequestConfig = {
            withCredentials: false,
            headers: {
                Authorization: `Bearer ${selector.authData?.access_token}`,
            },
            params: {
                tamanho: 1000,
            },
        };
        ConfiguracoesService.findAll(params).then((res) => setPage(res.data));
    }, [selector]);

    useEffect(() => {
        getConfiguracoes();
    }, [getConfiguracoes]);

    return (
        <div>
            <Dialog
                open={modalConfig}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                keepMounted
                onClose={() => onClose()}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{mt: 1}}
                    >
                        <Select
                            {...register("config")}
                            defaultValue="NONE"
                            sx={{maxHeight: 50, width: "100%"}}
                        >
                            <MenuItem disabled value="NONE">Selecione uma empresa</MenuItem>
                            {page?.content.map((item: ConfigResposta) => (
                                <MenuItem key={item.codigoUnico} value={item.codigoUnico}>
                                    {`${item.codigoUnico} - ${item.grupo}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Salvar
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MyModalChangeGrupo;
