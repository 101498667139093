import jwtDecode from "jwt-decode";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Acesso, TokenData} from "types/auth";
import {AuthService} from "services/AuthService";
import {toastError} from "utils/toastError";
import {PerfilService} from "services/PerfilService";
import Swal from "sweetalert2";
import {useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "store";
import {authActions, useAuthState, verifyAuthentication} from "store/auth";
import {useConfigContext} from "contexts/ConfigContext";
import { useConfigState } from "store/config";

type Props = {
    children: ReactNode;
}

type LoginState = {
    username: string;
    empresa: string;
    password: string;
}


export type AuthContext ={
    signIn: (data: LoginState) => Promise<void>;
    updatePerfil: (data: any) => Promise<void>;
    loginMfa: (codigo: string) => Promise<void>;
}


const AuthContextType = createContext<AuthContext>({} as AuthContext);


const AuthProvider = ({ children } : Props) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const selector = useAppSelector(useAuthState);
    const config = useAppSelector(useConfigState);
    const {getConfig, setLoadMfa} = useConfigContext();
    const [loginData, setLoginData] = useState<any>();

    useEffect(() => {
        if(!verifyAuthentication(selector)){
            dispatch(authActions.signOut());
        }
    }, [dispatch, selector]);

    
    const signIn = async ({ username, password, empresa }: LoginState) => {
        username = `${empresa}${username}`;
        try {
            const res: any = await AuthService.login({ username, password });
            const jwtDecoded = jwtDecode(res.data.access_token) as TokenData;
            let updatedConfig = config;
    
            if (jwtDecoded.authorities.includes('ROLE_GRUPO_EMPRESA')) {
                const configResponse = await getConfig(jwtDecoded.username.substring(0, 4), res.data.access_token);
                updatedConfig = configResponse ? { ...config, parametros: configResponse } : config;
            }
            if (jwtDecoded.authorities.includes('ROLE_COLABORADOR')) {
                let status = false;
                jwtDecoded.acessos.forEach((acesso: Acesso) => {
                    if (acesso.empresaCodigo.substring(0, 4) === empresa && acesso.status) {
                        status = true;
                    }
                });
                if (status) {
                    const configResponse  = await getConfig(empresa, res.data.access_token);
                    updatedConfig = configResponse ? { ...config, parametros: configResponse } : config;
                } else {
                    dispatch(authActions.signOut());
                    toast.error('Codigo incorreto ou acesso bloqueado!');
                    return;
                }
            }
    
            if (updatedConfig?.parametros?.mfa) {
                setLoginData(res.data);
                try {
                    await AuthService.sendMfa(jwtDecoded.username, {
                        headers: { 'Authorization': `Bearer ${res.data.access_token}` },
                        withCredentials: false,
                    });
                } catch (e) {
                    setLoadMfa(false);
                    toastError(e, "Erro ao enviar código de MFA");
                    return;
                }
            }

            if (!updatedConfig?.parametros?.mfa) {
                dispatch(authActions.signIn({ authData: res.data }));
            }
            return res;
        } catch (e) {
            toastError(e, "Erro ao realizar login");
        }
    };

    const loginMfa = async (codigo: string) => {
        const jwtDecoded = jwtDecode(loginData.access_token) as TokenData;
        try {
            const res = await AuthService.loginMfa(
                codigo,
                jwtDecoded.username.substring(4, 10),
                jwtDecoded.username.substring(0, 4),
                { 
                    headers: { 
                        'Authorization': `Bearer ${loginData.access_token}` 
                    }, 
                    withCredentials: false 
                }
            );
    
            if (res.data) {
                setLoadMfa(false);
                dispatch(authActions.signIn({ authData: loginData }));
            } else {
                throw new Error('Resposta inválida do servidor');
            }
        } catch (e) {
            toast.error('Código de MFA inválido!');
            throw e;
        }
    };

    const updatePerfil = async (data: any) => {
        PerfilService.postPerfil(data)
            .then(async (res) => {
                dispatch(authActions.updateName({name: res.data.nome}));
                await Swal.fire({
                    title: 'Salvo com sucesso !',
                    icon: 'success',
                    confirmButtonColor: theme.palette.primary.main,
                })
            })
            .catch(async (err) => {
                await Swal.fire({
                    title: 'Erro ao salvar !',
                    icon: 'error',
                    html: err?.response?.data?.message || 'Erro desocnhecido ao salvar !',
                    confirmButtonColor: theme.palette.primary.main,
                })
            })
    }


    return (
        <AuthContextType.Provider value={{updatePerfil, signIn, loginMfa}}>
            {children}
        </AuthContextType.Provider>
    )
}

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContextType);