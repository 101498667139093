import { AxiosRequestConfig } from "axios";
import { API } from "services/Api";

const URL = "/configuracoes";

const defaultParams: AxiosRequestConfig = {
    withCredentials: true,
};

let paramsGeral: AxiosRequestConfig = { ...defaultParams };

const resetParams = () => {
    paramsGeral = { ...defaultParams };
};

export const ConfiguracoesService = {
    findAll: async (params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.get(URL, paramsGeral);
        resetParams();
        return response;
    },
    findByCodigoCompl: async (codigo: string, params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.get(`${URL}/${codigo}`, paramsGeral);
        resetParams();
        return response;
    },
    findByCodigo: async (codigo: string, params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.get(`${URL}/codigo/${codigo}`, paramsGeral);
        resetParams();
        return response;
    },
    update: async (codigo: string, data: any, params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.put(`${URL}/${codigo}`, data, paramsGeral);
        resetParams();
        return response;
    },
    findAcessos: async (params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.get(`${URL}/acessos`, paramsGeral);
        resetParams();
        return response;
    },
    findByDominio: async (dominio: string, params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params, params: { ...paramsGeral.params, dominio } };
        const response = await API.get(`${URL}/dominio`, paramsGeral);
        resetParams();
        return response;
    },
    importXls: async (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.post(`${URL}/importar/xls`, data, paramsGeral);
        resetParams();
        return response;
    },
    importCsv: async (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.post(`${URL}/importar/csv`, data, paramsGeral);
        resetParams();
        return response;
    },
    importJson: async (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = { ...paramsGeral, ...params };
        const response = await API.post(`${URL}/importar/json`, data, paramsGeral);
        resetParams();
        return response;
    },
};
