import Box from "@mui/material/Box";
import {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import MyModalChangeEmpresa from "components/painelLayout/modal/changeEmpresa";
import MyModalChangeGrupo from "components/painelLayout/modal/changeGrupo";
import {
    Avatar,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Divider,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {authActions, hasAnyRoles, useAuthState} from "store/auth";
import { useConfigContext } from "contexts/ConfigContext";

const MyMenu = () => {
    const appSelector = useAppSelector(useAuthState)
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setLoadMfa } = useConfigContext();

    useEffect(() => {
        if(config.empresa && !config.empresa.empresaCodigo && !hasAnyRoles(appSelector, ['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA'])) {
            appDispatch(configActions.setModalEmpresa({
                modalEmpresa: true,
            }));
        }
    }, [appDispatch, appSelector, config.empresa]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };


    return (
        <Box>
            <>
                <ButtonGroup variant="contained" disableElevation  ref={anchorRef} aria-label="split button">
                    <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleToggle}
                    >
                        <Typography sx={{mr:1}}>
                            {appSelector.userName}
                        </Typography>
                        <Avatar />
                    </Button>
                </ButtonGroup>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem sx={{minWidth: 200}}>
                                        <MenuItem onClick={(e:any) => {
                                            handleClose(e);
                                            navigate('/painel/perfil');
                                        }}>Perfil</MenuItem>
                                        <Divider />
                                        {hasAnyRoles(appSelector,['ROLE_ADMIN']) &&
                                            <div>
                                                <MenuItem onClick={(e:any) => {
                                                    handleClose(e);
                                                    appDispatch(configActions.setModalConfig({
                                                        modalConfig: true,
                                                    }));
                                                }}>Alterar Grupo</MenuItem>
                                                <Divider />
                                            </div>
                                        }
                                        {!hasAnyRoles(appSelector,['ROLE_EMPRESA']) &&
                                            <div>
                                                <MenuItem onClick={(e:any) => {
                                                    handleClose(e);
                                                    appDispatch(configActions.setModalEmpresa({
                                                        modalEmpresa: true,
                                                    }));
                                                }}>Alterar Empresa</MenuItem>
                                                <Divider />
                                            </div>
                                        }
                                        <MenuItem onClick={() => {
                                            dispatch(authActions.signOut())
                                            dispatch(configActions.signout())
                                            setLoadMfa(false);
                                        }}>
                                            Sair
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
            {hasAnyRoles(appSelector,["ROLE_ADMIN"]) &&
                <MyModalChangeGrupo title={"Selecionar Grupo"} />
            }
            {config.parametros && !hasAnyRoles(appSelector, ['ROLE_EMPRESA']) &&
                <MyModalChangeEmpresa title={"Selecionar Empresa"} />
            }
        </Box>
    );
};
export default MyMenu;